@font-face {
    font-family: 'Antarctica';
    src: url('../fonts/Antarctica-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Antarctica Medium';
    src: url('../fonts/Antarctica-Medium.woff2') format('woff2');
}

@font-face {
    font-family: 'Antarctica Condensed Bold';
    src: url('../fonts/Antarctica-CondensedBold.woff2') format('woff2');
}

@font-face {
    font-family: 'Antarctica Condensed Semi Bold';
    src: url('../fonts/Antarctica-CondensedSemiBold.woff2') format('woff2');
}

@font-face {
    font-family: 'Antarctica Ultra Condensed Bold';
    src: url('../fonts/Antarctica-UltraCondensedBold.woff2') format('woff2');
}

@font-face {
    font-family: 'Antarctica Ultra Condensed Semi Bold';
    src: url('../fonts/Antarctica-UltraCondSemBd.woff2') format('woff2');
}

@font-face {
    font-family: 'Gellix';
    src: url('../fonts/Gellix-Regular.ttf');
}

@font-face {
    font-family: 'Integral';
    src: url('../fonts/integral-cf-bold.ttf');
}

@font-face {
    font-family: 'Integral Regular';
    src: url('../fonts/integral-cf-regular.ttf');
}