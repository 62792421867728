
/*========	Table of Css Content ==========*/
/* Typography */
/* Normalize  */
/* Global style */
/* Breadcrumb Area Start */
/* Header Area Start */
/* Footer Area Start */
/* Hero Area Start */
/* Features Area Start */
/* Featured Game Area Start */
/* Whay Choose us Area Start */
/* activities Area Start */
/* Fun fact Area Start */
/* Get Start Area Start */
/*  Recent Winners Area Start */
/*	Index 2 Css Start */
/* About Area Start */
/* About page section Area Start */
/* Our Vision Area Start */
/* Team Area Start */
/* community and Blog Area Start */
/* Play Games Area Start */
/* Lottery Staticstics Area Start */
/*  How it Work Area Start  */
/* lottery video Area Start */
/* Questions Area Start */
/* Cart Area Start */
/* Payment-method Area Start */
/* Tournaments Area Start */
/* Bonuses Area Start */
/*Deposit Bonuses Area Start */
/* Bonus banner Area Start */
/* Awards Area Start */
/*  How Play Area Start */
/*  Affiliate Process area Start */
/* Features Area Start */
/* Ref Commission Area Start  */
/* Marketing Tools Area Start */
/*Testimonial Area CSS Start*/
/* Serch Area Start */
/* Help Section Area Start */
/* Contact Area Start */
/* 404 Area Start */
/* faq-section css start */
/* login modal Area Start */


/* =========================
=============================
 */



// Sass Global
@import "main/variables";
@import "main/mixins";
@import "main/normalize";
@import "main/animation";
@import "main/global";

// Sass Sections
@import "sections/_header";
@import "sections/_footer";

// Sass page
//@import "pages/_home-page";
//@import "pages/_index2";
//@import "pages/_about";
//@import "pages/_play";
//@import "pages/_lottery";
//@import "pages/_cart";
//@import "pages/_tournaments";
//@import "pages/_bonus";
//@import "pages/_awards";
//@import "pages/_how-it-work";
//@import "pages/_affiliate";
//@import "pages/_trams-condition";
//@import "pages/_contact";
//@import "pages/_fourzero";
//@import "pages/_faq";
//@import "pages/_log-sign";
//@import "pages/_roadmap";
//@import "pages/_story";
@import "pages/_expeditions";

@import "colors";
@import "fonts";
@import "typography";

body{
  background: url('../images/background.png') center top no-repeat, #000000;
  background-size: 100% 100%;
  overflow: hidden;
}

.header .mainmenu-area{
  margin-top: 50px;
  background: transparent;
}

.logo{
  width: 325px;
}

.mybtn1, .mybtn2{
  background: linear-gradient(90deg, #FC384C 0%, #B31A2A 100%) !important;
  box-shadow: 0px 0px 10px #FC384C;
}

.header-logo{
  height: 150px;

  @media (max-width: 1199px) {
    height: 66px;
  }
  @media (max-width:991.98px){
    display: none;
  }
}

.hero-area{
  .links .mybtn1{
    height: 48px;
    font-size: 20px;
    line-height: 34px;
  }

  h5.subtitle{
    font-size: 24px !important;
  }
}

.roadmap li{
  list-style: initial;
  margin-left: 20px;
}

.qty {
  display: inline-block;
  float: left;
  padding: 4px 10px;
  border-radius: 50px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.3);
  background: #f9314b;
}
.qty ul li {
  display: inline-block;
}
.qty ul li:first-child {
  margin-left: 0px;
}
.qty ul li .qttotal,
.qty ul li .qtminus,
.qty ul li .qtplus {
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  cursor: pointer;
  font-weight: 500;
  color: #fff;
  transition: all 0.3s ease-in;
}
.qty ul li .qtminus:hover,
.qty ul li .qtplus:hover {
  color: #f9314b;
}
.qty ul li .qttotal:focus,
.qty ul li .qtminus:focus,
.qty ul li .qtplus:focus {
  outline: 0px;
}
.qty ul li .qttotal {
  width: 150px;
}

.alert{
  border: none;
  border-radius: 50px;
}

.alert-danger{
  background: #ff344d;
  color: white;
}

.alert-success{
  background: #6dfbc2;
  color: black;
}

.serch-area{
  background: transparent;
}

html.newsletter{
  html,body,.hero-area, .lottery-area{
    background: #000721;
    text-align: center;
  }

  .hero-area,
  .lottery-area{
    padding-bottom: 0;
  }

  @media (max-width: 769px) {
    .hero-area{
      padding-top: 0;
    }
  }

  .daily-lottery{
    padding: 0;
  }

  .serch-area{
    background: transparent;
  }

  .sub-title{
    display: flex;
    justify-content: center;

    div {
      max-width: 450px;
    }
  }

  .mybtn1{
    height: 50px;
  }

  .socials a{
    margin: 0 10px;
  }
}

@media (max-width: 991px) {
  .showcase{
    width: 100%;
  }
}

.overlay{
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2000;
  overflow: scroll;
}

.btn-reset{
  background: none !important;
  border: none !important;
  outline: none !important;

  &:hover{
    background: none !important;
  }
}

.cursor-pointer{
  cursor: pointer !important;
}

.btn-main{
  width: 329px;
  height: 80px;
}