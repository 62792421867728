
/* Header Area Start */
.header{
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    &.nav-fixed{
        background: #252f5a;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
        .top-header{
            display: none;
        }
        .navbar{
            &::after{
                visibility: hidden;
            }
            &::before{
                visibility: hidden;
            }
        }
    }
    /* Top Header Area Start */
    .top-header{
        background: $bg-color1;
        .content{
            display: flex;
            justify-content: space-between;
            padding: 13px 0px 13px;
            .left-content{
                .left-list{
                   li{
                       display: inline-block;
                       margin-right: 35px;
                       position: relative;
                       &::before{
                           position: absolute;
                           content: "|";
                           top: 0;
                           right: -23px;
                       }
                       &:last-child{
                           &::before{
                               display: none;
                           }
                       }
                    p{
                        margin-bottom: 0px;
                    }
                    .language-selector{
                        .language{
                            border: 0px;
                            background: none;
                            color: $body-color;
                        }
                    }
                    &:last-child{
                        margin-right: 0px;
                    }
                   }
                }
            }
            .right-content{
                text-align: right;
                .right-list{
                    > li{
                       display: inline-block;
                       margin-right: 35px;
                       position: relative;
                       &:last-child{
                           margin-right: 0px;
                       }
                       &::before{
                           position: absolute;
                           content: "|";
                           top: 0;
                           right: -23px;
                       }
                       &:last-child{
                           &::before{
                               display: none;
                           }
                       }
                    p{
                        margin-bottom: 0px;
                    }
                    .cart-icon{
                        position: relative;
                        .cart-count{
                            position: absolute;
                            width: 20px;
                            height: 20px;
                            color: #fff;
                            top: -7px;
                            right: -10px;
                            background: #252f5a;
                            font-size: 10px;
                            border-radius: 50px;
                            text-align: center;
                            line-height: 20px;
                            z-index: 7;
                        }
                    }
                    .notofication{
                        position: relative;
                        .count{
                            position: absolute;
                            width: 20px;
                            height: 20px;
                            color: #fff;
                            top: -7px;
                            right: -10px;
                            background: #252f5a;
                            font-size: 10px;
                            border-radius: 50px;
                            text-align: center;
                            line-height: 20px;
                            z-index: 7;
                        }
                    }
                    .tm-dropdown{
                        position: relative;
                        .tm-dropdown-menu{
                            position: absolute;
                            top: 100%;
                            left: 50%;
                            transform: translateX(-50%);
                            background: $bg-color2;
                            z-index: 9900;
                            width: 170px;
                            padding: 8px 15px 15px;
                            text-align: left;
                            display: none;
                            .list{
                                .list-item{
                                    display: flex;
                                    justify-content: space-between;
                                    margin-bottom: 5px;
                                    .close{
                                        color: #fff;
                                        font-size: 12px;
                                        margin-top: 10px;
                                        cursor: pointer;
                                        display: inline-block;
                                        margin-right: 5px;
                                    }
                                    .number-list{
                                        li{
                                            display: inline-block;
                                            width: 20px;
                                            height: 20px;
                                            font-size: 10px;
                                            line-height: 20px;
                                            font-weight: 600;
                                            margin: 0px;
                                        }
                                    }
                                }
                                li{
                                    a{
                                        display: block;
                                        color: $body-color;
                                        font-size: 14px;
                                        margin-bottom: 0px;
                                        transition: $transition;
                                        i{
                                            margin-right: 5px;
                                        }
                                        &:hover{
                                            color: $base-color;
                                        }
                                    }
                                    &::last-child{
                                        margin-bottom: 0px;
                                    }
                                }
                                
                            }
                            .link-btn{
                                margin-top: 14px;
                                display: block;
                                font-size: 12px;
                                text-align: center; 
                                text-transform: uppercase;
                                font-weight: 600;
                                padding: 4px 20px;
                                color: #fff;
                                border: 0px;
                                border-radius: 50px;
                                cursor: pointer;
                                transition: all 0.3s ease-in;
                                background-image: linear-gradient(to left, rgb(255, 52, 77), rgb(210, 39, 60));
                            
                                &:hover {
                                    background-image: linear-gradient(to right, rgb(255, 52, 77), rgb(210, 39, 60));
                                    color: #fff;
                                    transform: translateY(-2px);
                                }
                            }
                        }
                        &:hover{
                            .tm-dropdown-menu{
                                display: block;
                            }
                        }
                    }
                    .sign-in{
                        color: $body-color;
                    }
                }
            }
        }
    }
}

  
    /* Main Menu Area Start */  
    .mainmenu-area{
        background: #252f5a;
        .navbar{
            padding: 0px 0px;
            position: relative;
            //&::before{
            //    position: absolute;
            //    content: "";
            //    width: 1800px;
            //    height: 100%;
            //    top: 0;
            //    right: 100%;
            //    background: $bg-color1;
            //}
            //&::after{
            //    position: absolute;
            //    content: "";
            //    width: 0;
            //    height: 0;
            //    left: 0px;
            //    bottom: 0px;
            //    border-bottom: 83px solid $bg-color1;
            //    border-right: 35px solid transparent;
            //}
            
            .navbar-brand{
                padding: 0px;
                margin: 0px;
                padding-left: 50px;
            }
            .mybtn1{
                margin-left: 20px;
            }
            #main_menu{
                .navbar-nav{
                    .nav-item{
                        .nav-link{
                            color: white;
                            line-height: 26px;
                            font-size: 16px;
                            font-weight: 600;
                            position: relative;
                            padding: 47px 23px;
                            text-transform: uppercase;
                            transition: all linear .3s;
                            .mr-hover-effect{
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                left: 0;
                                width: 100%;
                                height: 50%;
                                opacity: 0;
                                border-top: 2px solid $base-color;
                                border-bottom: 2px solid $base-color;
                                transition: $transition;
                                &::after{
                                    position: absolute;
                                    content: "";
                                    top: 0px;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    width: 0;
                                    height: 0;
                                    border-left: 5px solid transparent;
                                    border-right: 5px solid transparent;
                                    border-top: 5px solid $base-color;
                                }
                                &::before{
                                    position: absolute;
                                    content: "";
                                    bottom: 0px;
                                    left: 50%;
                                    transform: translateX(-50%) rotate(180deg);
                                    width: 0;
                                    height: 0;
                                    border-left: 5px solid transparent;
                                    border-right: 5px solid transparent;
                                    border-top: 5px solid $base-color;
                                }
                            }
                            &.active,
                            &:hover{
                                color: $base-color;
                                .mr-hover-effect{
                                    opacity: 1;
                                    height: 100%;
                                }
                            }
                            
                        }
                        .dropdown-menu{
                            position: absolute;
                            z-index: 99;
                            margin-top: 0px !important;
                            background: $bg-color2;
                            border-radius: 0px;
                            border: 0px;
                            padding-bottom: 0px;
                            padding-top: 0px;
                            box-shadow: 0px 8px 14px 0px rgba(0, 0, 0, 0.15);
                            border-top: 4px solid $base-color;
                            transition: all linear .3s;
                            .dropdown-item{
                                line-height: 26px;
                                font-size: 16px;
                                font-weight: 600;
                                text-transform: uppercase;
                                padding: 8px 15px 8px;
                                border-bottom: 1px solid rgba(0, 0, 0, 0.10);
                                transition: all linear .3s;
                                color: $body-color;
                                i{
                                    margin-right: 5px;
                                    font-size: 12px;
                                }
                            
                                &:hover{
                                    background: $bg-color1;
                                }
                                &:focus{
                                    background: none;
                                }
                            }
                        }
                        &.dropdown{
                            &:hover{
                                .dropdown-menu{
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }  
        }
    }
}






