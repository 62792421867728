/* Global style */
.mybtn1 {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    /*padding: 11px 35px;*/
    display: inline-block;
    color: #fff;
    border: 0px;
    border-radius: 50px !important;
    cursor: pointer;
    transition: all 0.3s ease-in;
    background-image: linear-gradient(to left, rgb(255, 52, 77), rgb(210, 39, 60));
    outline: 0 !important;

    &:hover {
        background-image: linear-gradient(to right, rgb(255, 52, 77), rgb(210, 39, 60));
        color: #fff;
        transform: translateY(-2px);
    }
}

.mybtn2 {
  font-size: 17px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 23px 35px;
  display: inline-block;
  color: #E8E8E8;;
  border: 0px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease-in;
  background-image: linear-gradient(to left, rgb(255, 52, 77), rgb(210, 39, 60));

  &:hover {
    background-image: linear-gradient(to right, rgb(255, 52, 77), rgb(210, 39, 60));
    color: #fff;
    transform: translateY(-2px);
  }
}


@media (max-width: 1199px) {
  .mybtn1{
    font-size: 15px;
  }
  .mybtn2{
    font-size: 15px;
    padding: 20px 35px;
  }
}
@media (max-width:767.98px){
  .mybtn2{
    padding: 20px 25px;
  }
}
@media (max-width:575.98px) {
  .mybtn2{
    text-align: center;
  }
}


/* Section Heading Css */
.section-heading {
    text-align: center;

    .subtitle {
        font-size: 24px;
        line-height: 34px;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 8px;
        @include gradient-text();

        &.extra-padding {
            margin-bottom: 17px;
        }

    }

    .title {
        font-size: 48px;
        line-height: 72px;
        font-weight: 700;
        text-transform: uppercase;
        color: $heading-color;
        margin-bottom: 10px;
        text-shadow: 2px 2px 3px #2A3C46;

        &.extra-padding {
            margin-bottom: 20px;
        }
    }

    .text {
        font-size: 24px;
        line-height: 34px;
        font-weight: 300;
    }
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
.navbar-toggler {
    border: none!important;
    margin: 0px;
    padding: 0px;
}

.navbar-toggler:focus{
    outline: 0px;
}


/* Preloader Css */

.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $bg-color2;
    z-index: 9999999;
    transition: $transition;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
    &.hide {
        opacity: 0;
        display: none;
    }
  }
  
  .loader {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 75px;
    display: inline-block;
    vertical-align: middle;
  }
  
  .loader-1 .loader-outter {
    position: absolute;
    border: 4px solid $base-color;
    border-left-color: transparent;
    border-bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    -webkit-animation: loader-1-outter 1s cubic-bezier(.42, .61, .58, .41) infinite;
    animation: loader-1-outter 1s cubic-bezier(.42, .61, .58, .41) infinite;
  }
  
  .loader-1 .loader-inner {
    position: absolute;
    border: 4px solid $base-color;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    left: calc(50% - 20px);
    top: calc(50% - 20px);
    border-right: 0;
    border-top-color: transparent;
    -webkit-animation: loader-1-inner 1s cubic-bezier(.42, .61, .58, .41) infinite;
    animation: loader-1-inner 1s cubic-bezier(.42, .61, .58, .41) infinite;
  }
  
  @-webkit-keyframes loader-1-outter {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
  
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
  }
  
  @keyframes loader-1-outter {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
  
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
  }
  
  @-webkit-keyframes loader-1-inner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
  
    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
  }
  
  @keyframes loader-1-inner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
  }






/* bottomtotop Css */
.bottomtotop i {
    width: 50px;
    height: 50px;
    line-height: 50px;
    position: fixed;
    font-size: 14px;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    transform: rotate(-90deg);
    bottom: 15px;
    right: 15px;
    box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.2);
    z-index: 999;
    background: $base-color;
}

/* Breadcrumb Area Start */
.breadcrumb-area {
    padding: 310px 0px 186px;
    background: $bg-color1;
    position: relative;
    overflow: hidden;
    border-bottom: 1px solid rgba(255, 255, 255, 0.04);
    &.about{
        padding: 260px 0px 135px;
        .bc-img{
           position: absolute;
           right: 0px;
           bottom: 0px;
           max-width: 40%;
       }
    }
    &.bc-faq{
        padding: 260px 0px 135px;
        .title{
            margin-bottom: 23px;
        }
        .bc-img{
           position: absolute;
           right: 0px;
           bottom: 0px;
           max-width: 42%;
       }
    }
    &.play{
        padding: 260px 0px 277px;
        .bc-img{
           position: absolute;
           right: 0px;
           bottom: 0px;
           max-width: 55%;
       }
    }
    &.bc-lottery{
        padding: 260px 0px 193px;
        .bc-img{
            position: absolute;
            right: 0px;
            bottom: 0px;
            max-width: 52%;
       }
    }
    &.cart{
        padding: 260px 0px 135px;
        .bc-img{
           position: absolute;
           right: 0px;
           bottom: 0px;
           max-width: 42%;
       }
    }
    &.bc-tournaments{
        padding: 260px 0px 135px;
        .bc-img{
           position: absolute;
           right: 150px;
           bottom: 0px;
           max-width: 25%;
       }
    }
    &.bonus{
        padding: 260px 0px 135px;
        .bc-img{
           position: absolute;
           right: 150px;
           top: 160px;
       }
    }
    &.bonus{
        padding: 260px 0px 135px;
        .bc-img{
           position: absolute;
           right: 150px;
           top: 160px;
           max-width: 36%;
       }
    }
    
    &.bc-awards{
        padding: 260px 0px 135px;
        .bc-img{
           position: absolute;
           right: 0px;
           bottom: 0px;
           max-width: 50%;
       }
    }
    &.h-i-w{
        padding: 260px 0px 135px;
        .bc-img{
           position: absolute;
           right: 0px;
           bottom: 0px;
           max-width: 48%;
       }
    }
    &.bc-contact{
        padding: 260px 0px 135px;
        .bc-img{
            position: absolute;
            right: 0px;
            bottom: -35px;
            max-width: 27%;
       }
    }
    &.bc-affiliate{
        padding: 260px 0px 212px;
        .bc-img{
           position: absolute;
           right: 0px;
           bottom: 0px;
           max-width: 55%;
       }
    }
    &.bc-terms{
        padding: 260px 0px 190px;
        .bc-img{
           position: absolute;
           right: 0px;
           bottom: 0px;
           max-width: 46%;
       } 
    }
    .title {
        font-size: 70px;
        line-height: 80px;
        font-weight: 700;
        margin-bottom: 11px;
        text-transform: uppercase;
        color: #fff;

        &.extra-padding {
            margin-bottom: 26px;
        }
    }

    .breadcrumb-list {
        li {
            display: inline-block;

            span {
                margin: 0px 7px;
                color: #fff;
            }

            a {
                font-size: 16px;
                -webkit-transition: all 0.3s ease-in;
                -o-transition: all 0.3s ease-in;
                transition: all 0.3s ease-in;
                color: #fff;

                &:hover {
                    color: #f1f1f1;
                }

                &.active {
                    color: #f1f1f1;
                }
            }
        }
    }
}

/* Popup Video CSS */
.video-play-btn {
    display: inline-block;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    font-size: 26px;
    border-radius: 50%;
    position: relative;
    color: #fff;
    background: #fff;
    box-shadow: 0px 0px 0px 15px rgba(255, 255, 255, 0.2), 0px 0px 0px 30px rgba(255, 255, 255, 0.2),  0px 0px 0px 45px rgba(255, 255, 255, 0.2);
    animation: shadowAni 3s linear infinite;
    i{
        color: #fff;
    }
    
}




@keyframes shadowAni {
    0% {
        box-shadow: 0px 0px 0px 15px rgba(255, 255, 255, 0.2), 0px 0px 0px 30px rgba(255, 255, 255, 0.2),  0px 0px 0px 45px rgba(255, 255, 255, 0.2);
    }

    50% {
        box-shadow: 0px 0px 0px 20px rgba(255, 255, 255, 0.2), 0px 0px 0px 40px rgba(255, 255, 255, 0.2),  0px 0px 0px 60px rgba(255, 255, 255, 0.2);
    }
    100% {
        box-shadow: 0px 0px 0px 15px rgba(255, 255, 255, 0.2), 0px 0px 0px 30px rgba(255, 255, 255, 0.2),  0px 0px 0px 45px rgba(255, 255, 255, 0.2);
    }
}

.video-play-btn2 {
    display: inline-block;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    font-size: 26px;
    border-radius: 50%;
    position: absolute;
    color: #fff;
    background: #66d3b6;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    i{
        color: #fff;
    }
    &:hover{
        color: #fff;
    }
    &:after{
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        width: 200px;
        height: 200px;
        border-radius: 50%;
        border: 2px solid #fff;
        transform: translate(-50%, -50%);
    }
    &:before{
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        width: 300px;
        height: 300px;
        border-radius: 50%;
        border: 2px solid rgba(255, 255, 255, 0.60);
        transform: translate(-50%, -50%);
    }
    span{
        position: absolute;
        top: 50%;
        left: 50%;
        width: 400px;
        height: 400px;
        border-radius: 50%;
        border: 2px solid rgba(255, 255, 255, 0.40);
        transform: translate(-50%, -50%);
        &:before{
            position: absolute;
            content: '';
            top: 50%;
            left: 50%;
            width: 500px;
            height: 500px;
            border-radius: 50%;
            border: 2px solid rgba(255, 255, 255, 0.30);
            transform: translate(-50%, -50%);
        }
    }
}



/* Popup Video CSS */

div[class^="Snackbar_snackbar-wrapper__"]{
  z-index: 4000;
}