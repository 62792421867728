.headline-1{
    font-family: 'Antarctica Ultra Condensed Bold', serif;
    font-size: 210px;
    font-weight: bold;
    line-height: 94%;
    letter-spacing: .03em;
    text-transform: uppercase;
}

.headline-2{
    font-family: 'Integral', serif;
    font-size: 134px;
    font-weight: 600;
    line-height: 94%;
    letter-spacing: .03em;
    text-transform: uppercase;
}

.headline-3{
    font-family: 'Antarctica Condensed Bold', serif;
    font-size: 36px;
    font-weight: bold;
    line-height: 47px;
    letter-spacing: .02em;
    text-transform: uppercase;
    color: $pink-saturated;
}

.headline-4{
    font-family: 'Integral Regular', serif;
    font-size: 26px;
    font-weight: bold;
    line-height: 34px;
    letter-spacing: .24em;
    text-transform: uppercase;
}

.headline-5{
    font-family: "Antarctica", serif;
    font-size: 22px;
    line-height: 100%;
    letter-spacing: .02em;
    text-transform: uppercase;
}

.headline-6{
    font-family: 'Integral Regular', serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 26px;
    letter-spacing: .18em;
}

.quote-large{
    font-family: 'Antarctica', serif;
    font-size: 35px;
    line-height: 140%;
    letter-spacing: -.04em;
    text-shadow: 0px 2.28777px 13.7266px rgba(5, 20, 41, 0.7), 0px 4.57554px 80.0719px #051429, 0px 4.57554px 107.525px #051429;
}

.caption-large{
    font-family: 'Antarctica Condensed Bold', serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: .24em;
    text-transform: uppercase;
}

.caption-small{
    font-family: 'Antarctica Condensed Bold', serif;
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    letter-spacing: .1em;
    text-transform: uppercase;
}

.button-1{
    font-family: 'Antarctica Condensed Bold', serif;
    font-size: 24px;
    font-weight: bold;
    line-height: 31px;
    letter-spacing: .02em;
    text-transform: uppercase;
}

.button-2{
    font-family: 'Antarctica Condensed Semi Bold', serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: .04em;
    text-transform: uppercase;
}

.button-3{
    font-family: 'Antarctica Condensed Semi Bold', serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: .01em;
    text-transform: uppercase;
}

.body-1 {
    font-family: "Gellix", serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 156%;
    letter-spacing: -0.01em;
    text-align: justify;
    color: #FFFFFF;
    text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.6), 0px 0px 32px rgba(255, 255, 255, 0.2);
  }

.body-2{
    font-family: 'Antarctica Medium', serif;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -.01em;
    text-shadow: 0px 1px 3px rgba(20, 12, 9, 0.7), 0px 1px 10px rgba(20, 12, 9, 0.7);
}

.text-underline{
    text-decoration: underline;
}

@media screen and (max-width: 1023px) {
    .headline-1{
        font-size: 114px;
    }

    .headline-2{
        font-size: 69px;
    }

    .headline-3{
        font-size: 36px;
    }

    .headline-4{
        font-size: 19px;
    }

    .headline-5{
        font-size: 19px;
    }

    .quote-large{
        font-size: 20px;
    }

    .caption-large{
        font-size: 12px;
    }

    .caption-small{
        font-size: 11px;
    }

    .button-1{
        font-size: 20px;
    }

    .button-2{
        font-size: 18px;
    }

    .button-3{
        font-size: 16px;
    }

    .body-1{
        font-size: 18px;
    }

    .body-2{
        font-size: 14px;
    }
}

.text-white{
    color: $white;
}

.text-charcoal{
    color: $charcoal;
}

.text-green-bright{
    color: $green-bright;
}

.text-green-light{
    color: $green-light;
}

.text-green-dark{
    color: $green-dark;
}

.text-green-saturated{
    color: $green-saturated;
}

.text-turquoise-dark{
    color: $turquoise-dark;
}

.text-turquoise-saturated{
    color: $turquoise-saturated;
}

.text-orange-bright{
    color: $orange-bright;
}

.text-orange-light{
    color: $orange-light;
}

.text-orange{
    color: $orange;
}

.text-orange-dark{
    color: $orange-dark;
}

.text-orange-saturated{
    color: $orange-saturated;
}

.text-blue-light{
    color: $blue-light;
}

.text-blue-saturated{
    color: $blue-saturated;
}

.text-pink-light{
    color: $pink-light;
}

.text-pink{
    color: $pink;
}

.text-pink-saturated{
    color: $pink-saturated;
}