$white: #FFFFFF;
$charcoal: #0B0D0C;
$green-bright: #F7FFFC;
$green-light: #E1FEF4;
$green-dark: #257A51;
$green-saturated: #70E0AA;
$turquoise-dark: #257A6C;
$turquoise-saturated: #70E0CD;
$orange-bright: #FFFCF7;
$orange-light: #FFEED9;
$orange: #FFD7A6;
$orange-dark: #BE7519;
$orange-saturated: #FF8E00;
$blue-light: #E1FEFE;
$blue-saturated: #92FFFF;
$pink-light: #F3E2FB;
$pink: #FFA0F7;
$pink-saturated: #C34BFB;