/* faq-section css start */

.expeditions-section{
  //padding: 275px 0px 120px;
  //
  //@media (max-width: 1199px) {
  //  padding: 125px 0 50px;
  //}
  //@media (max-width: 991px) {
  //  padding: 50px 0 150px;
  //}

  .progress{
    background: rgba(240, 51, 70, 0.5);

    .progress-bar{
      background: #F03346;
    }

    .progress-bar-text{
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      color: #fff;
      text-align: center;
    }
  }
}

.nft-container{
  margin-bottom: 150px;
}

.expeditions-modal{
  position: absolute;
  max-width: 750px;
  max-height: 1150px;
  inset: 5% 40px 0 40px;
  padding: 50px 30px 30px 30px;
  bottom: unset;
  background: linear-gradient(0deg, rgba(37, 122, 94, 0.2), rgba(37, 122, 94, 0.2)), #000000;
  border: 1px solid rgba(112, 224, 187, 0.2);
  overflow: auto;
  outline: none;
  margin: 0 auto;
}

.completed{
  background: rgba(255, 142, 0, 0.6) !important;
}