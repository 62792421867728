/*========	Table of Css Content ==========*/
/* Typography */
/* Normalize  */
/* Global style */
/* Breadcrumb Area Start */
/* Header Area Start */
/* Footer Area Start */
/* Hero Area Start */
/* Features Area Start */
/* Featured Game Area Start */
/* Whay Choose us Area Start */
/* activities Area Start */
/* Fun fact Area Start */
/* Get Start Area Start */
/*  Recent Winners Area Start */
/*	Index 2 Css Start */
/* About Area Start */
/* About page section Area Start */
/* Our Vision Area Start */
/* Team Area Start */
/* community and Blog Area Start */
/* Play Games Area Start */
/* Lottery Staticstics Area Start */
/*  How it Work Area Start  */
/* lottery video Area Start */
/* Questions Area Start */
/* Cart Area Start */
/* Payment-method Area Start */
/* Tournaments Area Start */
/* Bonuses Area Start */
/*Deposit Bonuses Area Start */
/* Bonus banner Area Start */
/* Awards Area Start */
/*  How Play Area Start */
/*  Affiliate Process area Start */
/* Features Area Start */
/* Ref Commission Area Start  */
/* Marketing Tools Area Start */
/*Testimonial Area CSS Start*/
/* Serch Area Start */
/* Help Section Area Start */
/* Contact Area Start */
/* 404 Area Start */
/* faq-section css start */
/* login modal Area Start */
/* =========================
=============================
 */
/* Typography */
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800|Ubuntu:300,400,500,700|Poppins:300,400,500,700");

/* Normalize  */
html {
  font-family: "Poppins", sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  overflow-x: hidden;
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  margin: 0;
  color: #a1aed4;
  overflow-x: hidden;
}

body.modal-open {
  padding-right: 0px !important;
}

h1 {
  font-size: 60px;
  line-height: 1.0833333333;
}

h2 {
  font-size: 52px;
  line-height: 1.4444444444;
}

h3 {
  font-size: 26px;
  line-height: 1.0833333333;
}

h4 {
  font-size: 22px;
  line-height: 1.2380952381;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #E8E8E8;
  font-family: "Poppins", sans-serif;
}

p {
  font-size: 18px;
  color: #a1aed4;
  line-height: 1.625;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

a {
  color: #E8E8E8;
  text-decoration: none;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
}

a i {
  padding: 0 2px;
}

img {
  max-width: 100%;
}

ul {
  padding: 0;
  margin: 0;
}

ul li {
  list-style: none;
}

/*input and button type focus outline disable*/
input[type=text]:focus,
input[type=email]:focus,
input[type=url]:focus,
input[type=password]:focus,
input[type=search]:focus,
input[type=tel]:focus,
input[type=number]:focus,
textarea:focus,
input[type=button]:focus,
input[type=reset]:focus,
input[type=submit]:focus,
select:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid #ddd;
}

/**
 * 5.0 - Alignments
 */
.alignleft {
  float: left;
}

.alignright {
  float: right;
}

.aligncenter {
  clear: both;
  display: block;
  margin: 0 auto 1.75em;
}

@keyframes opacityAnimation {
  0% {
    opacity: 0.4;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.4;
  }
}

@keyframes upDownAnimation {
  0% {
    margin-top: 0px;
  }

  50% {
    margin-top: 80px;
  }

  100% {
    margin-top: 0px;
  }
}

@keyframes phoneAni {
  0% {
    margin: 0px;
  }

  33% {
    margin-left: 5px;
  }

  66% {
    margin-left: -5px;
  }

  100% {
    margin-top: 0px;
  }
}

@keyframes manAni {
  0% {
    margin: 0px;
  }

  33% {
    margin-top: 5px;
  }

  66% {
    margin-top: -5px;
  }

  100% {
    margin: 0px;
  }
}

@keyframes shieldAni {
  0% {
    margin: 0px;
  }

  33% {
    margin-top: 5px;
  }

  66% {
    margin-top: -5px;
  }

  100% {
    margin: 0px;
  }
}

@keyframes rippleAni {
  0% {
    transform: translate(0px, 0px);
  }

  33% {
    transform: translate(5px, -5px);
  }

  66% {
    transform: translate(-5px, 5px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes ripple2Ani {
  0% {
    transform: translate(0px, 0px);
  }

  33% {
    transform: translate(-5px, -5px);
  }

  66% {
    transform: translate(5px, 5px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

/* Global style */
.mybtn1 {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  /*padding: 11px 35px;*/
  display: inline-block;
  color: #fff;
  border: 0px;
  border-radius: 50px !important;
  cursor: pointer;
  transition: all 0.3s ease-in;
  background-image: linear-gradient(to left, rgb(255, 52, 77), rgb(210, 39, 60));
  outline: 0 !important;
}

.mybtn1:hover {
  background-image: linear-gradient(to right, rgb(255, 52, 77), rgb(210, 39, 60));
  color: #fff;
  transform: translateY(-2px);
}

.mybtn2 {
  font-size: 17px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 23px 35px;
  display: inline-block;
  color: #E8E8E8;
  border: 0px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease-in;
  background-image: linear-gradient(to left, rgb(255, 52, 77), rgb(210, 39, 60));
}

.mybtn2:hover {
  background-image: linear-gradient(to right, rgb(255, 52, 77), rgb(210, 39, 60));
  color: #fff;
  transform: translateY(-2px);
}

@media (max-width: 1199px) {
  .mybtn1 {
    font-size: 15px;
  }

  .mybtn2 {
    font-size: 15px;
    padding: 20px 35px;
  }
}

@media (max-width: 767.98px) {
  .mybtn2 {
    padding: 20px 25px;
  }
}

@media (max-width: 575.98px) {
  .mybtn2 {
    text-align: center;
  }
}

/* Section Heading Css */
.section-heading {
  text-align: center;
}

.section-heading .subtitle {
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 8px;
  background: -webkit-linear-gradient(to left, rgb(255, 52, 77), rgb(210, 39, 60));
  background: -moz-linear-gradient(to left, rgb(255, 52, 77), rgb(210, 39, 60));
  background: -ms-linear-gradient(to left, rgb(255, 52, 77), rgb(210, 39, 60));
  background: -o-linear-gradient(to left, rgb(255, 52, 77), rgb(210, 39, 60));
  background: linear-gradient(to left, rgb(255, 52, 77), rgb(210, 39, 60));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section-heading .subtitle.extra-padding {
  margin-bottom: 17px;
}

.section-heading .title {
  font-size: 48px;
  line-height: 72px;
  font-weight: 700;
  text-transform: uppercase;
  color: #E8E8E8;
  margin-bottom: 10px;
  text-shadow: 2px 2px 3px #2A3C46;
}

.section-heading .title.extra-padding {
  margin-bottom: 20px;
}

.section-heading .text {
  font-size: 24px;
  line-height: 34px;
  font-weight: 300;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-toggler {
  border: none !important;
  margin: 0px;
  padding: 0px;
}

.navbar-toggler:focus {
  outline: 0px;
}

/* Preloader Css */
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0b122e;
  z-index: 9999999;
  transition: all 0.3s ease-in;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.preloader.hide {
  opacity: 0;
  display: none;
}

.loader {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 75px;
  display: inline-block;
  vertical-align: middle;
}

.loader-1 .loader-outter {
  position: absolute;
  border: 4px solid #f9314b;
  border-left-color: transparent;
  border-bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-animation: loader-1-outter 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
  animation: loader-1-outter 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
}

.loader-1 .loader-inner {
  position: absolute;
  border: 4px solid #f9314b;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
  border-right: 0;
  border-top-color: transparent;
  -webkit-animation: loader-1-inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
  animation: loader-1-inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
}

@-webkit-keyframes loader-1-outter {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-1-outter {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader-1-inner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

@keyframes loader-1-inner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

/* bottomtotop Css */
.bottomtotop i {
  width: 50px;
  height: 50px;
  line-height: 50px;
  position: fixed;
  font-size: 14px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  transform: rotate(-90deg);
  bottom: 15px;
  right: 15px;
  box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.2);
  z-index: 999;
  background: #f9314b;
}

/* Breadcrumb Area Start */
.breadcrumb-area {
  padding: 310px 0px 186px;
  background: #070b28;
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid rgba(255, 255, 255, 0.04);
}

.breadcrumb-area.about {
  padding: 260px 0px 135px;
}

.breadcrumb-area.about .bc-img {
  position: absolute;
  right: 0px;
  bottom: 0px;
  max-width: 40%;
}

.breadcrumb-area.bc-faq {
  padding: 260px 0px 135px;
}

.breadcrumb-area.bc-faq .title {
  margin-bottom: 23px;
}

.breadcrumb-area.bc-faq .bc-img {
  position: absolute;
  right: 0px;
  bottom: 0px;
  max-width: 42%;
}

.breadcrumb-area.play {
  padding: 260px 0px 277px;
}

.breadcrumb-area.play .bc-img {
  position: absolute;
  right: 0px;
  bottom: 0px;
  max-width: 55%;
}

.breadcrumb-area.bc-lottery {
  padding: 260px 0px 193px;
}

.breadcrumb-area.bc-lottery .bc-img {
  position: absolute;
  right: 0px;
  bottom: 0px;
  max-width: 52%;
}

.breadcrumb-area.cart {
  padding: 260px 0px 135px;
}

.breadcrumb-area.cart .bc-img {
  position: absolute;
  right: 0px;
  bottom: 0px;
  max-width: 42%;
}

.breadcrumb-area.bc-tournaments {
  padding: 260px 0px 135px;
}

.breadcrumb-area.bc-tournaments .bc-img {
  position: absolute;
  right: 150px;
  bottom: 0px;
  max-width: 25%;
}

.breadcrumb-area.bonus {
  padding: 260px 0px 135px;
}

.breadcrumb-area.bonus .bc-img {
  position: absolute;
  right: 150px;
  top: 160px;
}

.breadcrumb-area.bonus {
  padding: 260px 0px 135px;
}

.breadcrumb-area.bonus .bc-img {
  position: absolute;
  right: 150px;
  top: 160px;
  max-width: 36%;
}

.breadcrumb-area.bc-awards {
  padding: 260px 0px 135px;
}

.breadcrumb-area.bc-awards .bc-img {
  position: absolute;
  right: 0px;
  bottom: 0px;
  max-width: 50%;
}

.breadcrumb-area.h-i-w {
  padding: 260px 0px 135px;
}

.breadcrumb-area.h-i-w .bc-img {
  position: absolute;
  right: 0px;
  bottom: 0px;
  max-width: 48%;
}

.breadcrumb-area.bc-contact {
  padding: 260px 0px 135px;
}

.breadcrumb-area.bc-contact .bc-img {
  position: absolute;
  right: 0px;
  bottom: -35px;
  max-width: 27%;
}

.breadcrumb-area.bc-affiliate {
  padding: 260px 0px 212px;
}

.breadcrumb-area.bc-affiliate .bc-img {
  position: absolute;
  right: 0px;
  bottom: 0px;
  max-width: 55%;
}

.breadcrumb-area.bc-terms {
  padding: 260px 0px 190px;
}

.breadcrumb-area.bc-terms .bc-img {
  position: absolute;
  right: 0px;
  bottom: 0px;
  max-width: 46%;
}

.breadcrumb-area .title {
  font-size: 70px;
  line-height: 80px;
  font-weight: 700;
  margin-bottom: 11px;
  text-transform: uppercase;
  color: #fff;
}

.breadcrumb-area .title.extra-padding {
  margin-bottom: 26px;
}

.breadcrumb-area .breadcrumb-list li {
  display: inline-block;
}

.breadcrumb-area .breadcrumb-list li span {
  margin: 0px 7px;
  color: #fff;
}

.breadcrumb-area .breadcrumb-list li a {
  font-size: 16px;
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  color: #fff;
}

.breadcrumb-area .breadcrumb-list li a:hover {
  color: #f1f1f1;
}

.breadcrumb-area .breadcrumb-list li a.active {
  color: #f1f1f1;
}

/* Popup Video CSS */
.video-play-btn {
  display: inline-block;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  font-size: 26px;
  border-radius: 50%;
  position: relative;
  color: #fff;
  background: #fff;
  box-shadow: 0px 0px 0px 15px rgba(255, 255, 255, 0.2), 0px 0px 0px 30px rgba(255, 255, 255, 0.2), 0px 0px 0px 45px rgba(255, 255, 255, 0.2);
  animation: shadowAni 3s linear infinite;
}

.video-play-btn i {
  color: #fff;
}

@keyframes shadowAni {
  0% {
    box-shadow: 0px 0px 0px 15px rgba(255, 255, 255, 0.2), 0px 0px 0px 30px rgba(255, 255, 255, 0.2), 0px 0px 0px 45px rgba(255, 255, 255, 0.2);
  }

  50% {
    box-shadow: 0px 0px 0px 20px rgba(255, 255, 255, 0.2), 0px 0px 0px 40px rgba(255, 255, 255, 0.2), 0px 0px 0px 60px rgba(255, 255, 255, 0.2);
  }

  100% {
    box-shadow: 0px 0px 0px 15px rgba(255, 255, 255, 0.2), 0px 0px 0px 30px rgba(255, 255, 255, 0.2), 0px 0px 0px 45px rgba(255, 255, 255, 0.2);
  }
}

.video-play-btn2 {
  display: inline-block;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  font-size: 26px;
  border-radius: 50%;
  position: absolute;
  color: #fff;
  background: #66d3b6;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-play-btn2 i {
  color: #fff;
}

.video-play-btn2:hover {
  color: #fff;
}

.video-play-btn2:after {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 2px solid #fff;
  transform: translate(-50%, -50%);
}

.video-play-btn2:before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.6);
  transform: translate(-50%, -50%);
}

.video-play-btn2 span {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.4);
  transform: translate(-50%, -50%);
}

.video-play-btn2 span:before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.3);
  transform: translate(-50%, -50%);
}

/* Popup Video CSS */
div[class^=Snackbar_snackbar-wrapper__] {
  z-index: 4000;
}

/* Header Area Start */
.header {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  /* Top Header Area Start */
  /* Main Menu Area Start */
}

.header.nav-fixed {
  background: #252f5a;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
}

.header.nav-fixed .top-header {
  display: none;
}

.header.nav-fixed .navbar::after {
  visibility: hidden;
}

.header.nav-fixed .navbar::before {
  visibility: hidden;
}

.header .top-header {
  background: #070b28;
}

.header .top-header .content {
  display: flex;
  justify-content: space-between;
  padding: 13px 0px 13px;
}

.header .top-header .content .left-content .left-list li {
  display: inline-block;
  margin-right: 35px;
  position: relative;
}

.header .top-header .content .left-content .left-list li::before {
  position: absolute;
  content: "|";
  top: 0;
  right: -23px;
}

.header .top-header .content .left-content .left-list li:last-child::before {
  display: none;
}

.header .top-header .content .left-content .left-list li p {
  margin-bottom: 0px;
}

.header .top-header .content .left-content .left-list li .language-selector .language {
  border: 0px;
  background: none;
  color: #a1aed4;
}

.header .top-header .content .left-content .left-list li:last-child {
  margin-right: 0px;
}

.header .top-header .content .right-content {
  text-align: right;
}

.header .top-header .content .right-content .right-list>li {
  display: inline-block;
  margin-right: 35px;
  position: relative;
}

.header .top-header .content .right-content .right-list>li:last-child {
  margin-right: 0px;
}

.header .top-header .content .right-content .right-list>li::before {
  position: absolute;
  content: "|";
  top: 0;
  right: -23px;
}

.header .top-header .content .right-content .right-list>li:last-child::before {
  display: none;
}

.header .top-header .content .right-content .right-list>li p {
  margin-bottom: 0px;
}

.header .top-header .content .right-content .right-list>li .cart-icon {
  position: relative;
}

.header .top-header .content .right-content .right-list>li .cart-icon .cart-count {
  position: absolute;
  width: 20px;
  height: 20px;
  color: #fff;
  top: -7px;
  right: -10px;
  background: #252f5a;
  font-size: 10px;
  border-radius: 50px;
  text-align: center;
  line-height: 20px;
  z-index: 7;
}

.header .top-header .content .right-content .right-list>li .notofication {
  position: relative;
}

.header .top-header .content .right-content .right-list>li .notofication .count {
  position: absolute;
  width: 20px;
  height: 20px;
  color: #fff;
  top: -7px;
  right: -10px;
  background: #252f5a;
  font-size: 10px;
  border-radius: 50px;
  text-align: center;
  line-height: 20px;
  z-index: 7;
}

.header .top-header .content .right-content .right-list>li .tm-dropdown {
  position: relative;
}

.header .top-header .content .right-content .right-list>li .tm-dropdown .tm-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: #0b122e;
  z-index: 9900;
  width: 170px;
  padding: 8px 15px 15px;
  text-align: left;
  display: none;
}

.header .top-header .content .right-content .right-list>li .tm-dropdown .tm-dropdown-menu .list .list-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.header .top-header .content .right-content .right-list>li .tm-dropdown .tm-dropdown-menu .list .list-item .close {
  color: #fff;
  font-size: 12px;
  margin-top: 10px;
  cursor: pointer;
  display: inline-block;
  margin-right: 5px;
}

.header .top-header .content .right-content .right-list>li .tm-dropdown .tm-dropdown-menu .list .list-item .number-list li {
  display: inline-block;
  width: 20px;
  height: 20px;
  font-size: 10px;
  line-height: 20px;
  font-weight: 600;
  margin: 0px;
}

.header .top-header .content .right-content .right-list>li .tm-dropdown .tm-dropdown-menu .list li a {
  display: block;
  color: #a1aed4;
  font-size: 14px;
  margin-bottom: 0px;
  transition: all 0.3s ease-in;
}

.header .top-header .content .right-content .right-list>li .tm-dropdown .tm-dropdown-menu .list li a i {
  margin-right: 5px;
}

.header .top-header .content .right-content .right-list>li .tm-dropdown .tm-dropdown-menu .list li a:hover {
  color: #f9314b;
}

.header .top-header .content .right-content .right-list>li .tm-dropdown .tm-dropdown-menu .list li::last-child {
  margin-bottom: 0px;
}

.header .top-header .content .right-content .right-list>li .tm-dropdown .tm-dropdown-menu .link-btn {
  margin-top: 14px;
  display: block;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  padding: 4px 20px;
  color: #fff;
  border: 0px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease-in;
  background-image: linear-gradient(to left, rgb(255, 52, 77), rgb(210, 39, 60));
}

.header .top-header .content .right-content .right-list>li .tm-dropdown .tm-dropdown-menu .link-btn:hover {
  background-image: linear-gradient(to right, rgb(255, 52, 77), rgb(210, 39, 60));
  color: #fff;
  transform: translateY(-2px);
}

.header .top-header .content .right-content .right-list>li .tm-dropdown:hover .tm-dropdown-menu {
  display: block;
}

.header .top-header .content .right-content .right-list>li .sign-in {
  color: #a1aed4;
}

.header .mainmenu-area {
  background: #252f5a;
}

.header .mainmenu-area .navbar {
  padding: 0px 0px;
  position: relative;
}

.header .mainmenu-area .navbar .navbar-brand {
  padding: 0px;
  margin: 0px;
  padding-left: 50px;
}

.header .mainmenu-area .navbar .mybtn1 {
  margin-left: 20px;
}

.header .mainmenu-area .navbar #main_menu .navbar-nav .nav-item .nav-link {
  color: white;
  line-height: 26px;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  padding: 47px 23px;
  text-transform: uppercase;
  transition: all linear 0.3s;
}

.header .mainmenu-area .navbar #main_menu .navbar-nav .nav-item .nav-link .mr-hover-effect {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  height: 50%;
  opacity: 0;
  border-top: 2px solid #f9314b;
  border-bottom: 2px solid #f9314b;
  transition: all 0.3s ease-in;
}

.header .mainmenu-area .navbar #main_menu .navbar-nav .nav-item .nav-link .mr-hover-effect::after {
  position: absolute;
  content: "";
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #f9314b;
}

.header .mainmenu-area .navbar #main_menu .navbar-nav .nav-item .nav-link .mr-hover-effect::before {
  position: absolute;
  content: "";
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%) rotate(180deg);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #f9314b;
}

.header .mainmenu-area .navbar #main_menu .navbar-nav .nav-item .nav-link.active,
.header .mainmenu-area .navbar #main_menu .navbar-nav .nav-item .nav-link:hover {
  color: #f9314b;
}

.header .mainmenu-area .navbar #main_menu .navbar-nav .nav-item .nav-link.active .mr-hover-effect,
.header .mainmenu-area .navbar #main_menu .navbar-nav .nav-item .nav-link:hover .mr-hover-effect {
  opacity: 1;
  height: 100%;
}

.header .mainmenu-area .navbar #main_menu .navbar-nav .nav-item .dropdown-menu {
  position: absolute;
  z-index: 99;
  margin-top: 0px !important;
  background: #0b122e;
  border-radius: 0px;
  border: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
  box-shadow: 0px 8px 14px 0px rgba(0, 0, 0, 0.15);
  border-top: 4px solid #f9314b;
  transition: all linear 0.3s;
}

.header .mainmenu-area .navbar #main_menu .navbar-nav .nav-item .dropdown-menu .dropdown-item {
  line-height: 26px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 8px 15px 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  transition: all linear 0.3s;
  color: #a1aed4;
}

.header .mainmenu-area .navbar #main_menu .navbar-nav .nav-item .dropdown-menu .dropdown-item i {
  margin-right: 5px;
  font-size: 12px;
}

.header .mainmenu-area .navbar #main_menu .navbar-nav .nav-item .dropdown-menu .dropdown-item:hover {
  background: #070b28;
}

.header .mainmenu-area .navbar #main_menu .navbar-nav .nav-item .dropdown-menu .dropdown-item:focus {
  background: none;
}

.header .mainmenu-area .navbar #main_menu .navbar-nav .nav-item.dropdown:hover .dropdown-menu {
  display: block;
}

/* Footer Area Start */
.footer {
  padding-top: 60px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center 140px;
  background-size: cover;
  z-index: 1000;
  overflow: visible;
}

@media (max-width: 991px) {
  .footer {
    padding-top: 10px;
  }
}

.footer:not(.fixed-bottom) {
  position: relative;
}

.footer .footer-logo {
  margin-top: -10px;
  margin-bottom: 25px;
}

.footer .footer-widget .title {
  text-transform: uppercase;
  font-size: 28px;
  line-height: 38px;
  font-weight: 600;
}

.footer .footer-widget ul {
  padding-left: 0px;
  margin-bottom: 0px;
}

.footer .footer-widget ul li {
  list-style: none;
}

.footer .footer-widget ul li a {
  transition: all 0.3s ease-in;
  line-height: 40px;
  font-weight: 400;
  color: #a1aed4;
}

.footer .footer-widget ul li a:hover {
  color: #f9314b;
  padding-left: 10px;
}

.footer .footer-widget ul li a i {
  font-size: 15px;
  padding-right: 4px;
}

.footer .copy-bg {
  z-index: 10;
  margin-top: 110px;
  padding: 43px 15px 30px;
  background: black;
}

.footer .copy-bg .left-area p {
  font-size: 14px;
  line-height: 21px;
  color: #E8E8E8;
  text-shadow: 2px 2px 3px #2A3C46;
  margin-bottom: 0;
}

.footer .copy-bg .left-area a {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 0px;
  color: #045cf0;
}

.footer .copy-bg .copright-area-links {
  text-align: right;
}

.footer .copy-bg .copright-area-links li {
  display: inline-block;
  margin-right: 30px;
  position: relative;
}

.footer .copy-bg .copright-area-links li:last-child {
  margin-right: 0px;
}

.footer .copy-bg .copright-area-links li:last-child::after {
  display: none;
}

.footer .copy-bg .copright-area-links li a {
  font-size: 16px;
  font-weight: 400;
  color: #a1aed4;
  transition: all 0.3s ease-in;
}

.footer .copy-bg .copright-area-links li a:hover {
  color: #f9314b;
}

.footer .subscribe-box {
  margin-bottom: 110px;
  z-index: 99;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 53px 60px 55px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}

.footer .subscribe-box .heading-area {
  text-align: center;
}

.footer .subscribe-box .heading-area .sub-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
  text-transform: uppercase;
  margin-bottom: 10px;
  background: -webkit-linear-gradient(to left, rgb(255, 52, 77), rgb(210, 39, 60));
  background: -moz-linear-gradient(to left, rgb(255, 52, 77), rgb(210, 39, 60));
  background: -ms-linear-gradient(to left, rgb(255, 52, 77), rgb(210, 39, 60));
  background: -o-linear-gradient(to left, rgb(255, 52, 77), rgb(210, 39, 60));
  background: linear-gradient(to left, rgb(255, 52, 77), rgb(210, 39, 60));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.footer .subscribe-box .heading-area .title {
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
  margin-bottom: 50px;
  text-transform: uppercase;
}

.footer .subscribe-box .icon {
  width: 100%;
  text-align: right;
}

.footer .subscribe-box .icon img {
  display: inline-block;
}

.footer .subscribe-box .form-area {
  width: 100%;
}

.footer .subscribe-box .form-area input {
  width: 100%;
  height: 50px;
  background: none;
  border: 0px;
  border-bottom: 1px solid #b4b8d1;
  border-radius: 0px;
  color: #a1aed4;
}

.footer .subscribe-box .form-area input::-webkit-input-placeholder {
  color: #a1aed4;
}

.footer .subscribe-box .form-area input:-moz-placeholder {
  color: #a1aed4;
}

.footer .subscribe-box .form-area input::-moz-placeholder {
  color: #a1aed4;
}

.footer .subscribe-box .form-area input:-ms-input-placeholder {
  color: #a1aed4;
}

.footer .subscribe-box .button-area {
  width: 100%;
}

.footer .subscribe-box .button-area .mybtn1 {
  display: block;
  width: 100%;
  height: 50px;
  position: relative;
  background-image: linear-gradient(to right, rgb(255, 52, 77), rgb(210, 39, 60));
}

.footer .subscribe-box .button-area .mybtn1 span {
  position: absolute;
  top: 0;
  right: 0px;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  background: #db5263;
  border-radius: 50%;
}

.footer .subscribe-box .button-area .mybtn1:hover {
  background-image: linear-gradient(to left, rgb(255, 52, 77), rgb(210, 39, 60));
}

.footer .footer-bg {
  position: absolute;
  bottom: -1000px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  width: 100vw;
}

/* faq-section css start */
.expeditions-section .progress {
  background: rgba(240, 51, 70, 0.5);
}

.expeditions-section .progress .progress-bar {
  background: #F03346;
}

.expeditions-section .progress .progress-bar-text {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  text-align: center;
}

.nft-container {
  margin-bottom: 150px;
}

.expeditions-modal {
  position: absolute;
  max-width: 750px;
  max-height: 1150px;
  inset: 5% 40px 0 40px;
  padding: 50px 30px 30px 30px;
  bottom: unset;
  background: linear-gradient(0deg, rgba(37, 122, 94, 0.2), rgba(37, 122, 94, 0.2)), #000000;
  border: 1px solid rgba(112, 224, 187, 0.2);
  overflow: auto;
  outline: none;
  margin: 0 auto;
}

.completed {
  background: rgba(255, 142, 0, 0.6) !important;
}

@font-face {
  font-family: "Antarctica";
  src: url("../fonts/Antarctica-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Antarctica Medium";
  src: url("../fonts/Antarctica-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Antarctica Condensed Bold";
  src: url("../fonts/Antarctica-CondensedBold.woff2") format("woff2");
}

@font-face {
  font-family: "Antarctica Condensed Semi Bold";
  src: url("../fonts/Antarctica-CondensedSemiBold.woff2") format("woff2");
}

@font-face {
  font-family: "Antarctica Ultra Condensed Bold";
  src: url("../fonts/Antarctica-UltraCondensedBold.woff2") format("woff2");
}

@font-face {
  font-family: "Antarctica Ultra Condensed Semi Bold";
  src: url("../fonts/Antarctica-UltraCondSemBd.woff2") format("woff2");
}

.headline-1 {
  font-family: "Antarctica Ultra Condensed Bold", serif;
  font-size: 210px;
  font-weight: bold;
  line-height: 94%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}

.headline-2 {
  font-family: "Antarctica Ultra Condensed Semi Bold", serif;
  font-size: 134px;
  font-weight: 600;
  line-height: 94%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}

.headline-3 {
  font-family: "Antarctica Condensed Bold", serif;
  font-size: 36px;
  font-weight: bold;
  line-height: 47px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #C34BFB;
}

.headline-4 {
  font-family: "Antarctica Condensed Bold", serif;
  font-size: 26px;
  font-weight: bold;
  line-height: 34px;
  letter-spacing: 0.24em;
  text-transform: uppercase;
}

.headline-5 {
  font-family: "Antarctica", serif;
  font-size: 22px;
  line-height: 100%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.headline-6 {
  font-family: "Antarctica Condensed Bold", serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 26px;
  letter-spacing: 0.18em;
}

.headline-7 {
  font-family: 'Antarctica';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 34px;
  text-align: center;
  letter-spacing: 0.24em;
  text-transform: uppercase;
  color: #FFD7A6 !important;
  text-shadow: -6px 0px 15px rgba(255, 142, 0, 0.4);
}

.headline-8 {
  font-family: "Antarctica";
  font-size: 26px !important;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.24em;
  text-align: center;
  color: #70E0AA !important;
}



.quote-large {
  font-family: "Antarctica", serif;
  font-size: 35px;
  line-height: 140%;
  letter-spacing: -0.04em;
  text-shadow: 0px 2.28777px 13.7266px rgba(5, 20, 41, 0.7), 0px 4.57554px 80.0719px #051429, 0px 4.57554px 107.525px #051429;
}

.caption-large {
  font-family: "Antarctica Condensed Bold", serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.24em;
  text-transform: uppercase;
}

.caption-small {
  font-family: "Antarctica Condensed Bold", serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.button-1 {
  font-family: "Antarctica Condensed Bold", serif;
  font-size: 24px;
  font-weight: bold;
  line-height: 31px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.button-2 {
  font-family: "Antarctica Condensed Semi Bold", serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

.button-3 {
  font-family: "Antarctica Condensed Semi Bold", serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}

.body-1 {
  font-family: "Antarctica", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 156%;
  letter-spacing: -0.01em;
  text-align: justify;
  color: #FFFFFF;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.6), 0px 0px 32px rgba(255, 255, 255, 0.2);
}

.body-2 {
  font-family: "Antarctica Medium", serif;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.01em;
  text-shadow: 0px 1px 3px rgba(20, 12, 9, 0.7), 0px 1px 10px rgba(20, 12, 9, 0.7);
}

.text-underline {
  text-decoration: underline;
}

@media screen and (max-width: 1023px) {
  .headline-1 {
    font-size: 114px;
  }

  .headline-2 {
    font-size: 69px;
  }

  .headline-3 {
    font-size: 36px;
  }

  .headline-4 {
    font-size: 19px;
  }

  .headline-5 {
    font-size: 19px;
  }

  .quote-large {
    font-size: 20px;
  }

  .caption-large {
    font-size: 12px;
  }

  .caption-small {
    font-size: 11px;
  }

  .button-1 {
    font-size: 20px;
  }

  .button-2 {
    font-size: 18px;
  }

  .button-3 {
    font-size: 16px;
  }

  .body-1 {
    font-size: 18px;
  }

  .body-2 {
    font-size: 14px;
  }
}

.text-white {
  color: #FFFFFF;
}

.text-charcoal {
  color: #0B0D0C;
}

.text-green-bright {
  color: #F7FFFC;
}

.text-green-light {
  color: #E1FEF4;
}

.text-green-dark {
  color: #257A51;
}

.text-green-saturated {
  color: #70E0AA;
}

.text-turquoise-dark {
  color: #257A6C;
}

.text-turquoise-saturated {
  color: #70E0CD;
}

.text-orange-bright {
  color: #FFFCF7;
}

.text-orange-light {
  color: #FFEED9;
}

.text-orange {
  color: #FFD7A6;
}

.text-orange-dark {
  color: #BE7519;
}

.text-orange-saturated {
  color: #FF8E00;
}

.text-blue-light {
  color: #E1FEFE;
}

.text-blue-saturated {
  color: #92FFFF;
}

.text-pink-light {
  color: #F3E2FB;
}

.text-pink {
  color: #FFA0F7;
}

.text-pink-saturated {
  color: #C34BFB;
}

body {
  background: url("../images/background.png") center top no-repeat, #000000;
  background-size: 100% 100%;
  overflow: hidden;
}

.header .mainmenu-area {
  margin-top: 50px;
  background: transparent;
}

.logo {
  width: 325px;
}

.mybtn1,
.mybtn2 {
  background: linear-gradient(90deg, #FC384C 0%, #B31A2A 100%) !important;
  box-shadow: 0px 0px 10px #FC384C;
}

.header-logo {
  height: 150px;
}

@media (max-width: 1199px) {
  .header-logo {
    height: 66px;
  }
}

@media (max-width: 991.98px) {
  .header-logo {
    display: none;
  }
}

.hero-area .links .mybtn1 {
  height: 48px;
  font-size: 20px;
  line-height: 34px;
}

.hero-area h5.subtitle {
  font-size: 24px !important;
}

.roadmap li {
  list-style: initial;
  margin-left: 20px;
}

.qty {
  display: inline-block;
  float: left;
  padding: 4px 10px;
  border-radius: 50px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.3);
  background: #f9314b;
}

.qty ul li {
  display: inline-block;
}

.qty ul li:first-child {
  margin-left: 0px;
}

.qty ul li .qttotal,
.qty ul li .qtminus,
.qty ul li .qtplus {
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  cursor: pointer;
  font-weight: 500;
  color: #fff;
  transition: all 0.3s ease-in;
}

.qty ul li .qtminus:hover,
.qty ul li .qtplus:hover {
  color: #f9314b;
}

.qty ul li .qttotal:focus,
.qty ul li .qtminus:focus,
.qty ul li .qtplus:focus {
  outline: 0px;
}

.qty ul li .qttotal {
  width: 150px;
}

.alert {
  border: none;
  border-radius: 50px;
}

.alert-danger {
  background: #ff344d;
  color: white;
}

.alert-success {
  background: #6dfbc2;
  color: black;
}

.serch-area {
  background: transparent;
}

html.newsletter html,
html.newsletter body,
html.newsletter .hero-area,
html.newsletter .lottery-area {
  background: #000721;
  text-align: center;
}

html.newsletter .hero-area,
html.newsletter .lottery-area {
  padding-bottom: 0;
}

@media (max-width: 769px) {
  html.newsletter .hero-area {
    padding-top: 0;
  }
}

html.newsletter .daily-lottery {
  padding: 0;
}

html.newsletter .serch-area {
  background: transparent;
}

html.newsletter .sub-title {
  display: flex;
  justify-content: center;
}

html.newsletter .sub-title div {
  max-width: 450px;
}

html.newsletter .mybtn1 {
  height: 50px;
}

html.newsletter .socials a {
  margin: 0 10px;
}

@media (max-width: 991px) {
  .showcase {
    width: 100%;
  }
}

.overlay {
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2000;
  overflow: scroll;
}

.btn-reset {
  background: none !important;
  border: none !important;
  outline: none !important;
}

.btn-reset:hover {
  background: none !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.btn-main {
  width: 329px;
  height: 80px;
}

